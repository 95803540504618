<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wra>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            Schools Services
            <v-spacer></v-spacer>

            <!-- <add-button
              :on-current-batch="false"
              permission="school-create"
              @action="$router.push({ name: 'schools-form' })"
              >Add 
            </add-button> -->
            <!--<v-btn outlined @click="" ma-0 small>New School</v-btn>-->
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="schools.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="totalData"
          >
            <!-- :server-items-length="schools.meta.total" -->
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + schools.meta.from }}</td>
                <td class="text-xs-left">{{ item.name }}</td>

                <td class="text-xs-left">
                  <small v-for="s in item.services" :key="s.id">
                    <v-chip class="ma-1" color="primary">
                      {{ s.name }} 
                      <v-icon small 
                        v-if="s.name == 'LIBRARY' && !item.display_library_reload_icon"
                        @click="resyncToken(item.id,s.name,s.id,index)"
                        >autorenew</v-icon>
                        <v-progress-circular
                            indeterminate
                            color="#ccc"
                            v-if="s.name == 'LIBRARY' && item.display_library_reload_icon"
                            :size="20"
                          ></v-progress-circular>

                      <v-icon small 
                        v-if="s.name == 'SMS' && !item.display_sms_renew_icon" 
                        @click="resyncToken(item.id,s.name,s.id,index)"
                        >autorenew</v-icon>
                          
                        <v-progress-circular
                            indeterminate
                            color="#ccc"
                            v-if="s.name == 'SMS' && item.display_sms_renew_icon"
                            :size="20"
                          ></v-progress-circular>
                    </v-chip>
                  </small>
                </td>

                <td class="text-xs-left">
                  {{ item.domain }}
                </td>
                <td class="text-right">
                  <edit-button
                    label="Add Service"
                    permission="school-update"
                    message="Are you sure you want to add services"
                    @agree="populateData(item)"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="displayModal" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add Services</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-form ref="form">
            <v-text-field
              autocomplete="off"
              label="Company Name"
              class="pa-0 mt-3"
              outlined
              dense
              :disabled="true"
              v-model="form.company_name"
            />
            <v-select
              :items="unavailable_services"
              class="pa-0"
              label="Select Services"
              outlined
              dense
              v-model="form.service_id"
              name="company_services"
              :error-messages="form.errors.get('company_services')"
              :rules="[(v) => !!v || 'Serivice is required']"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" text @click="reset()">Close</v-btn>
              <v-btn color="success" text @click="save()"
                >Save</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Form from "@/library/Form";

import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    schools: [],
    displayModal: false,
    totalData: 0,
    headers: [
      { text: "#", align: "center", value: "id", width: 10, sortable: false },
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: false,
        width: 150,
      },
      {
        text: "Services",
        align: "left",
        value: "services",
        sortable: false,
        width: 500,
      },
      {
        text: "Domain",
        align: "left",
        value: "domain",
        sortable: false,
        width: 30,
      },
      { text: "Action", align: "right", sortable: false, width: 20 },
    ],
    services: [],
    unavailable_services: [],
    form: new Form(
      {
        company_name: "",
        service_id: "",
        company_id: "",
      },
      "api/company-services"
    ),
  }),
  watch: {
    pagination: function () {
      this.get();
    },
  },
  methods: {
    save(){
      if (this.$refs.form.validate()) {
        this.form.store().then((res) => {
          this.displayModal = false;
          this.$events.fire("notification", { message: res.data.message });
          this.reset();
          this.get();
          
        }).catch((err)=>{
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        })
      }
    },
    get() {
      let extraParams = "";
      let query = this.queryString(extraParams);

      this.$rest.get(`/api/company-services${query}`).then(({ data }) => {
        this.schools = data;
        this.totalData = data.meta.total
        // this.pagination.totalItems = data.meta.total;
      });
      this.getServices();
    },
    getServices() {
      this.$rest.get("/api/service").then(({ data }) => {
        this.services = data.data;
      });
    },
    populateData(data) {
      this.displayModal = true;

      this.form.company_id = data.id;
      this.form.company_name = data.name;

      let all_services_id = this.services.map((s) => s.id);
      let available_serives_id = data.services.map((a) => a.id);

      let filtered_array = all_services_id.filter(function (item) {
        return !available_serives_id.includes(item);
      });
      let filtered_services = [];
      filtered_array.forEach((item, index) => {
      if(item ==2 || item == 9)
        filtered_services.push(this.services.find(el=>el.id == item));
     
      });
      this.unavailable_services = filtered_services.map((itm) =>{
            return {value:itm.id, text:itm.name}
      });
    },
    resyncToken(comp_id,s_name,s_id,index,){
      if(s_name === 'LIBRARY')
        this.schools.data[index].display_library_reload_icon = true;
      if(s_name === 'SMS')
        this.schools.data[index].display_sms_renew_icon = true;
      this.$rest.post('/api/resync-token',{
        company_id:comp_id,
        service_id:s_id,
        service_name:s_name
      }).then((res)=>{
        this.$events.fire("notification", { message: res.data.message });

      }).catch((err)=>{
        this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
      }).finally((f)=>{
          this.schools.data[index].display_library_reload_icon = false;
          this.schools.data[index].display_sms_renew_icon = false;
      })
    },
    reset() {
      this.displayModal = false;
      this.form.reset();
      this.unavailable_services = [];
    },
  },
};
</script>
